body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */

@font-face {
  font-family: "Raleway";
  src: local("Raleway-Regular"), url(./fonts/Raleway-Regular.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway-Medium"), url(./fonts/Raleway-Medium.ttf) format("truetype");
  font-style: medium;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway-Bold"), url(./fonts/Raleway-Bold.ttf) format("truetype");
  font-style: bold;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway-Italic"), url(./fonts/Raleway-Italic.ttf) format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway-Thin"), url(./fonts/Raleway-Thin.ttf) format("truetype");
  font-style: thin;
}